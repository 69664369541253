import styled from 'styled-components';
import {
  BODY3,
  Button,
  Card,
  Container,
  H2,
  H4,
} from '@tuunetech/tuune-components';

export const Alert = styled(Card)`
  padding: 10px 20px;
  min-width: 60vw;
  color: ${({ theme }) => theme.palette.text.light};
  background-color: ${({ theme }) => theme.palette.error.main};
`;

export const StyledButton = styled(Button)<{ $error: boolean }>`
  border-color: ${({ theme, $error }) =>
    $error ? theme.palette.error.main : theme.palette.border.main};
  color: ${({ theme, $error }) =>
    $error ? theme.palette.error.main : theme.palette.border.main};
  width: 100%;
  position: relative;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

export const ContactContainer = styled(Container)`
  margin: 130px 0 80px;

  form {
    width: 100%;
  }

  ${H2} {
    margin-bottom: 20px;
  }

  ${H4} {
    margin: 30px 0 15px;
    text-align: left;
  }

  ${BODY3} {
    margin-top: 11px;
    text-align: left;
  }

  // TODO: define one UI variant for the TextField and use it as shared
  // or two, e.g. Outlined, Standard

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-input {
    height: 60px;
    padding: 16px 10px;
    box-sizing: border-box;
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    line-height: ${({ theme }) => theme.typography.body1.lineHeight};
    color: ${({ theme }) => theme.palette.common.grey[600]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  & .MuiOutlinedInput-root {
    & fieldset,
    &:hover fieldset :not(&.Mui-error) {
      border-color: ${({ theme }) => theme.palette.border.main};
    }

    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.common.purple[900]};
    }

    &.Mui-focused.Mui-error fieldset {
      border-color: ${({ theme }) => theme.palette.error.main};
    }
  }

  .MuiInputBase-inputMultiline {
    height: 105px !important;
  }
`;

export const FileAttached = styled.div`
  display: flex;
  align-items: center;
  margin-top: 11px;

  & > ${BODY3} {
    margin-top: 0;
    margin-right: 15px;
  }
`;
