import React, { useCallback, useState } from 'react';
import { TextField, Box, Snackbar, CircularProgress } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import {
  BODY1,
  BODY3,
  Button,
  GridContainer,
  GridItem,
  H2,
  H4,
} from '@tuunetech/tuune-components';
import { ContactContainer, Alert } from './styles';

export const ContactLayout: React.FC = () => {
  const [isEmailSent, setIsEmailSent] = useState<boolean | undefined>(
    undefined,
  );
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useForm();

  const submit = useCallback(async ({ email, subject, description }) => {
    try {
      setIsFormSubmitting(true);
      if (!process.env.GATSBY_MAIL_CONTACT) {
        throw Error('No email server found');
      }
      const response = await fetch(process.env.GATSBY_MAIL_CONTACT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, subject, description }),
      });
      const result = await response.json();
      if (!result.error) {
        setIsEmailSent(true);
        setIsFormSubmitting(false);
      } else {
        throw new Error(result.error);
      }
    } catch (e) {
      setIsEmailSent(false);
      setIsFormSubmitting(false);
    }
  }, []);

  const handleClose = useCallback(() => {
    setIsEmailSent(undefined);
  }, []);

  return (
    <>
      <Snackbar
        open={isEmailSent === false}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert>Error occur while sending email</Alert>
      </Snackbar>
      <ContactContainer>
        <GridContainer>
          {isEmailSent ? (
            <GridItem xs={12} md={7} xl={6}>
              <H2>Request submitted </H2>
              <BODY1>
                Thank you! :) Your request has been successfully submitted and
                we’ll review it soon.
              </BODY1>
            </GridItem>
          ) : (
            <GridItem xs={12} md={7} xl={6}>
              <H2>Submit a request</H2>
              <form onSubmit={handleSubmit(submit)}>
                <label htmlFor="email">
                  <H4 color="primary">Your email address</H4>
                </label>
                <TextField
                  name="email"
                  id="email"
                  variant="outlined"
                  placeholder="user@tuune.com"
                  inputRef={register({
                    required: 'Email is required.',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Please enter a valid email address',
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
                <label htmlFor="subject">
                  <H4 color="primary">Subject</H4>
                </label>
                <TextField
                  name="subject"
                  size="small"
                  id="subject"
                  variant="outlined"
                  inputRef={register({
                    required: 'Subject: cannot be blank',
                  })}
                  error={!!errors.subject}
                  helperText={errors.subject?.message}
                />
                <label htmlFor="desc">
                  <H4 color="primary">Description</H4>
                </label>
                <TextField
                  id="desc"
                  name="description"
                  variant="outlined"
                  multiline
                  placeholder="Type your request..."
                  inputRef={register({
                    required: 'Description: cannot be blank',
                  })}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
                <BODY3 color="textSecondary">
                  Please enter the details of your request. A member of our
                  support staff will respond as soon as possible.
                </BODY3>
                <Box marginTop="27px">
                  <Button type="submit" disabled={isFormSubmitting}>
                    {isFormSubmitting && (
                      <CircularProgress color="inherit" size={12} />
                    )}
                    Submit
                  </Button>
                </Box>
              </form>
            </GridItem>
          )}
        </GridContainer>
      </ContactContainer>
    </>
  );
};
