import React from 'react';
import { PageProps } from 'gatsby';
import { Layout } from 'components/shared';
import { ContactLayout } from 'components/Contact';

const Contact: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <ContactLayout />
    </Layout>
  );
};

export default Contact;
